<template lang="html">
  <b-card :title="$t('search.searchPhrasesWithNoResults.header')">
    <SearchPhrasesWithNoResults
      :start-date="start"
      :end-date="end"
      :type="type"
      :download="$t('search.searchPhrasesWithNoResults.download')"
    />
  </b-card>
</template>

<script>
import SearchPhrasesWithNoResults from '@/views/components/search/Report/SearchPhrasesWithNoResults.vue'
import store from '@/store'

export default {
  components: { SearchPhrasesWithNoResults },
  computed: {
    start() {
      return store.state.search.dateRange.start
    },
    end() {
      return store.state.search.dateRange.end
    },
    type() {
      if (store.state.search.activeDataSource === null) {
        return 'all'
      }
      return store.state.search.activeDataSource.value
    },
  },
}
</script>

<style>
  .report-table table th:not(:first-child) .vs-table-text {
    text-align: right;
    display: block;
  }
</style>
